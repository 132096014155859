import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "detail" }
const _hoisted_2 = { class: "detail__container mb-3" }
const _hoisted_3 = { class: "detail__header d-flex align-center" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = {
  key: 0,
  class: "bull-name-container"
}
const _hoisted_6 = {
  key: 0,
  class: "bull-name"
}
const _hoisted_7 = { class: "bull-subname" }
const _hoisted_8 = { class: "detail__select" }
const _hoisted_9 = { class: "detail__tab-container" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "detail__collapse-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_GeneralView = _resolveComponent("GeneralView")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_PedigreeView = _resolveComponent("PedigreeView")!
  const _component_BreedingValuesView = _resolveComponent("BreedingValuesView")!
  const _component_GenomicsView = _resolveComponent("GenomicsView")!
  const _component_DocumentationFormulasView = _resolveComponent("DocumentationFormulasView")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_SireFooter = _resolveComponent("SireFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.noPedigreeFound)
        ? (_openBlock(), _createBlock(_component_v_alert, {
            key: 0,
            icon: _ctx.mdiAlert,
            class: "mb-6",
            type: "error",
            variant: "tonal",
            title: _ctx.$t('details.pedigree-warning-alert-title'),
            closable: ""
          }, null, 8, ["icon", "title"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_v_icon, {
              icon: _ctx.mdiChevronLeft,
              class: "mr-4 d-none d-md-block",
              onClick: _ctx.goBackToSearch
            }, null, 8, ["icon", "onClick"])
          ]),
          (_ctx.bullDetail)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_ctx.bullDetail)
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_6, _toDisplayString(_ctx.formatEmptyToDash(_ctx.bullDetail.shortName ? _ctx.bullDetail.shortName : _ctx.bullDetail.fullName)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.bullDetail.fullName ? _ctx.bullDetail.fullName : '-') + " | " + _toDisplayString(_ctx.bullDetail.artificialInseminationNumber) + " | " + _toDisplayString(_ctx.bullDetail.interbullNumber), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_v_select, {
            items: _ctx.getHairColorOptions,
            density: "compact",
            label: _ctx.$t('details.basic-calc'),
            "bg-color": "white",
            modelValue: _ctx.getHairColorValue,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getHairColorValue) = $event)),
              _ctx.handleSelectBase
            ],
            "item-title": "optionText",
            "item-value": "id",
            "return-object": "",
            variant: "underlined",
            color: "primary"
          }, null, 8, ["items", "label", "modelValue", "onUpdate:modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_v_tabs, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.tab) = $event)),
          color: "primary"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_tab, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setGeneralView())),
              color: _ctx.baseBlue,
              value: "General"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('details.header-overview')), 1)
              ]),
              _: 1
            }, 8, ["color"]),
            _createVNode(_component_v_tab, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPedigreeView())),
              color: _ctx.baseBlue,
              value: "Pedigree"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('details.header-origin')), 1)
              ]),
              _: 1
            }, 8, ["color"]),
            _createVNode(_component_v_tab, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setBreedingValue())),
              color: _ctx.baseBlue,
              value: "BreedingValues"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('details.header-breeding')), 1)
              ]),
              _: 1
            }, 8, ["color"]),
            _createVNode(_component_v_tab, {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setGenomicsView())),
              color: _ctx.baseBlue,
              value: "Genomics"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('details.header-genomics')), 1)
              ]),
              _: 1
            }, 8, ["color"]),
            _createVNode(_component_v_tab, {
              onClick: _ctx.setDocumentationView,
              color: _ctx.baseBlue,
              value: "Documentation"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('header.tab-docs')), 1)
              ]),
              _: 1
            }, 8, ["onClick", "color"])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_window, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tab) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_window_item, { value: "General" }, {
              default: _withCtx(() => [
                (!_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_GeneralView, { key: 0 }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_v_progress_circular, {
                        indeterminate: "",
                        color: "#154775",
                        "model-value": "20"
                      })
                    ]))
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { value: "Pedigree" }, {
              default: _withCtx(() => [
                _createVNode(_component_PedigreeView)
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { value: "BreedingValues" }, {
              default: _withCtx(() => [
                (!_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_BreedingValuesView, { key: 0 }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(_component_v_progress_circular, {
                        indeterminate: "",
                        color: "#154775",
                        "model-value": "20"
                      })
                    ]))
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { value: "Genomics" }, {
              default: _withCtx(() => [
                (!_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_GenomicsView, {
                      key: 0,
                      hasMarkerEffects: _ctx.hasMarkerEffects
                    }, null, 8, ["hasMarkerEffects"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(_component_v_progress_circular, {
                        indeterminate: "",
                        color: "#154775",
                        "model-value": "20"
                      })
                    ]))
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { value: "Documentation" }, {
              default: _withCtx(() => [
                _createVNode(_component_DocumentationFormulasView)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_v_expansion_panels, {
          variant: "accordion",
          multiple: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setGeneralView()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('details.header-overview')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_GeneralView)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setPedigreeView()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('details.header-origin')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_PedigreeView)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setBreedingValue()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('details.header-breeding')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_BreedingValuesView)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setGenomicsView()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('details.header-genomics')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_GenomicsView, { hasMarkerEffects: _ctx.hasMarkerEffects }, null, 8, ["hasMarkerEffects"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setDocumentationView()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('header.tab-docs')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_DocumentationFormulasView)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_SireFooter)
  ], 64))
}