import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header_desktop d-none d-sm-block" }
const _hoisted_2 = { class: "top-bar d-flex" }
const _hoisted_3 = { class: "second-layer" }
const _hoisted_4 = { class: "header_mobile d-flex d-sm-none" }
const _hoisted_5 = { class: "top-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_crvLogo = _resolveComponent("crvLogo")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_select, {
          "menu-props": { theme: 'light' },
          class: "language-selector",
          modelValue: _ctx.$i18n.locale,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
            _ctx.handleSelectLocale
          ],
          "item-value": "param",
          "item-title": (item) => `${item.item} (${item.title})`,
          items: _ctx.LayoutLanguages,
          variant: "underlined"
        }, {
          selection: _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(item.raw.title), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "item-title", "items", "onUpdate:modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_crvLogo, {
          onClick: _ctx.goBackHome,
          class: "crv-logo ml-12"
        }, null, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_v_icon, {
          icon: _ctx.mdiChevronLeft,
          class: "ml-3",
          onClick: _ctx.goBackToSearch
        }, null, 8, ["icon", "onClick"]),
        _createVNode(_component_crvLogo, {
          class: "crv-logo pa-2",
          onClick: _ctx.goBackToSearch
        }, null, 8, ["onClick"]),
        _createVNode(_component_v_select, {
          "menu-props": { theme: 'light' },
          class: "language-selector",
          modelValue: _ctx.$i18n.locale,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
            _ctx.handleSelectLocale
          ],
          "item-value": "param",
          "item-title": (item) => `${item.item} (${item.title})`,
          items: _ctx.LayoutLanguages,
          variant: "underlined"
        }, {
          selection: _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(item.raw.title), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "item-title", "items", "onUpdate:modelValue"])
      ])
    ])
  ], 64))
}