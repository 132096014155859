// TODO: candidate for Monorepo service function
export const sort = (a, b, property) => {
    // vuetify.d.ts shim is necessary to avoid typescript error when using sortRaw in headers of v-data-table
    // V-data-table doesn't seem to have proper documentation on adding custom-sorting.
    // Below's function add Natural sorting, plus places the 'empty' or '-' below the rest of the values.
    
    if (a[property] < b[property]) return -1
    if (a[property] > b[property]) return 1
    
    return 0
}