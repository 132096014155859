<template>
    <v-container fluid class="pl-0 pr-0">
        <h2 class="pl-md-0 pl-3">{{ $t("formulas.header") }}</h2>
        <v-btn 
            variant="text"
            color="primary"
            class="mb-6"
            :append-icon="mdiOpenInNew"
            @click="openChapterLink"
        >
            {{ $t("formulas.chapters") }}
        </v-btn>
        
        <h2 class="pl-md-0 pl-3">{{ $t("formulas.source.header") }}</h2>
        <v-row class="mb-6">
            <v-col cols="12" md="6" class="pr-md-6 pr-sm-0">
                <v-table density="compact" class="fixed-width-first-child">
                    <tbody>
                        <tr>
                            <td>Nat</td>
                            <td>{{ $t("formulas.source.text.nat") }}</td>
                        </tr>
                        <tr>
                            <td>Int</td>
                            <td>{{ $t("formulas.source.text.int") }}</td>
                        </tr>
                        <tr>
                            <td>OFw</td>
                            <td>{{ $t("formulas.source.text.ofw") }}</td>
                        </tr>
                        <tr>
                            <td>GNat</td>
                            <td>{{ $t("formulas.source.text.gnat") }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-6 pl-sm-0">
                <v-table density="compact" class="fixed-width-first-child">
                    <tbody>
                        <tr>
                            <td>GFw</td>
                            <td>{{ $t("formulas.source.text.gfw") }}</td>
                        </tr>
                        <tr>
                            <td>IGFw</td>
                            <td>{{ $t("formulas.source.text.igfw") }}</td>
                        </tr>
                        <tr>
                            <td>Gint</td>
                            <td>{{ $t("formulas.source.text.gint") }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
        </v-row>

        <h2 class="pl-md-0 pl-3 mb-1">{{ $t("details.summary.first.inheritedFactors") }}</h2>
        <p class="pl-md-0 pl-3 mb-1">{{ $t('formulas.inheritedLegend.description') }}</p>
        <p class="pl-md-0 pl-3 mb-1">{{ $t('formulas.inheritedLegend.plus') }}</p>
        <p class="pl-md-0 pl-3 mb-1">{{ $t('formulas.inheritedLegend.minus') }}</p>
        <p class="pl-md-0 pl-3 mb-1">{{ $t('formulas.inheritedLegend.asterix') }}</p>

        <v-row class="mb-6">
            <v-col cols="12" md="6" class="pr-md-6 pr-sm-0">
                <v-table density="compact" class="fixed-width-first-child">
                    <tbody>
                        <tr>
                            <td>BD</td>
                            <td>{{ $t("formulas.inherited.bd") }}</td>
                        </tr>
                        <tr>
                            <td>BL</td>
                            <td>{{ $t("formulas.inherited.bl") }}</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>{{ $t("formulas.inherited.br") }}</td>
                        </tr>
                        <tr>
                            <td>BY</td>
                            <td>{{ $t("formulas.inherited.by") }}</td>
                        </tr>
                        <tr>
                            <td>CD</td>
                            <td>{{ $t("formulas.inherited.cd") }}</td>
                        </tr>
                        <tr>
                            <td>CV</td>
                            <td>{{  $t("formulas.inherited.cv") }}</td>
                        </tr>
                        <tr>
                            <td>D1</td>
                            <td>{{ $t("formulas.inherited.d1") }}</td>
                        </tr>
                        <tr>
                            <td>D2</td>
                            <td>{{ $t("formulas.inherited.d2") }}</td>
                        </tr>
                        <tr>
                            <td>DP</td>
                            <td>{{ $t("formulas.inherited.dp") }}</td>
                        </tr>
                        <tr>
                            <td>GG</td>
                            <td>{{ $t("formulas.inherited.gg") }}</td>
                        </tr>
                        <tr>
                            <td>GT</td>
                            <td>{{ $t("formulas.inherited.gt") }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-6 pl-sm-0">
                <v-table density="compact" class="fixed-width-first-child">
                    <tbody>
                        <tr>
                            <td>LR</td>
                            <td>{{ $t("formulas.inherited.lr") }}</td>
                        </tr>
                        <tr>
                            <td>MF</td>
                            <td>{{ $t("formulas.inherited.mf") }}</td>
                        </tr>
                        <tr>
                            <td>MW</td>
                            <td>{{ $t("formulas.inherited.mw") }}</td>
                        </tr>
                        <tr>
                            <td>OT</td>
                            <td>{{ $t("formulas.inherited.ot") }}</td>
                        </tr>
                        <tr>
                            <td>PO</td>
                            <td>{{  $t("formulas.inherited.po") }}</td>
                        </tr>
                        <tr>
                            <td>QT</td>
                            <td>{{ $t("formulas.inherited.qt") }}</td>
                        </tr>
                        <tr>
                            <td>RF</td>
                            <td>{{ $t("formulas.inherited.rf") }}</td>
                        </tr>
                        <tr>
                            <td>RT</td>
                            <td>{{ $t("formulas.inherited.rt") }}</td>
                        </tr>
                        <tr>
                            <td>TV</td>
                            <td>{{ $t("formulas.inherited.tv") }}</td>
                        </tr>
                        <tr>
                            <td>VR</td>
                            <td>{{ $t("formulas.inherited.vr") }}</td>
                        </tr>
                        <tr>
                            <td>ZN</td>
                            <td>{{ $t("formulas.inherited.zn") }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6" class="pr-md-6 pr-sm-0">
                <h2 class="pl-md-0 pl-3 mb-1">{{ $t("details.summary.first.kinshipRate") }}</h2>
                <p class="pl-md-0 pl-3 mb-4">{{ $t('formulas.kinshipRate.text-one') }}</p>
                <p class="pl-md-0 pl-3 mb-4">{{ $t('formulas.kinshipRate.text-two') }}</p>
                <p class="pl-md-0 pl-3 mb-4">{{ $t('formulas.kinshipRate.text-three') }}</p>
                <p class="pl-md-0 pl-3 mb-4">{{ $t('formulas.kinshipRate.text-four') }}</p>
                <p class="pl-md-0 pl-3 mb-4">{{ $t('formulas.kinshipRate.text-five') }}</p>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-6 pl-sm-0">
                <h2 class="pl-md-0 pl-3 mb-1">{{ $t("formulas.relative.header") }}</h2>
                <p class="pl-md-0 pl-3 mb-6">{{ $t("formulas.relative.text") }}</p>

                <v-table density="compact" class="fixed-width-first-child">
                    <thead>
                        <tr>
                            <th v-for="item in headerRows[0].cells" :key="item.id">
                                {{ item.value }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in bodyRows" :key="row.id">
                            <td v-for="cell in  row.cells" :key="cell.id">{{ cell.value }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatNumber } from "@/scripts/format-number";
import { DocumentationHeaders } from "../models/table-headers";
import { mdiOpenInNew } from '@mdi/js';
import { formatLocale } from "../scripts/format-locale";

export default defineComponent({
    name: "DocumentationFormulasView",
    methods: {
        formatNumber(value: string) {
            return formatNumber(value)
        },
        openChapterLink() {
            window.open(this.$t('formulas.link'))
        }
    },
    data() {
        return {
            mdiOpenInNew,
            headerRows: [
                {
                    "id": "relative",
                    "cells": DocumentationHeaders
                }
            ],
            bodyRows: [
                {
                    "id": 1,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "<88"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(0.1, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 2,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "88-91"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(2.2, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 3,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "92-95"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(13.6, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 4,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "96-104"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(68.2, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 5,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "105-108"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(13.6, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 6,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "109-112"
                        },
                        {
                            "id": "freq",

                            "value": this.$n(2.2, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 7,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": ">112"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(0.1, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                }
            ],
        };
    },
});
</script>

<style lang="scss" scoped>

.fixed-width-first-child {
    tr td:first-child {
    width: 50px;
    }
}
</style>