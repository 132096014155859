import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "pl-md-0 pl-3" }
const _hoisted_2 = { class: "pl-md-0 pl-3" }
const _hoisted_3 = { class: "pl-md-0 pl-3" }
const _hoisted_4 = { class: "pl-md-0 pl-3" }
const _hoisted_5 = { class: "pl-md-0 pl-3" }
const _hoisted_6 = { class: "pl-md-0 pl-3" }
const _hoisted_7 = { class: "pl-md-0 pl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_GeneralTableView = _resolveComponent("GeneralTableView")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CustomTableView = _resolveComponent("CustomTableView")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "pl-0 pr-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            class: "pr-md-6 pr-sm-0"
          }, {
            default: _withCtx(() => [
              (!_ctx.hasMarkerEffects)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 0,
                    icon: _ctx.mdiAlert,
                    class: "mb-6",
                    type: "warning",
                    variant: "tonal",
                    title: _ctx.$t('details.genomics-warning-alert-title')
                  }, null, 8, ["icon", "title"]))
                : (_openBlock(), _createBlock(_component_v_alert, {
                    key: 1,
                    color: "info",
                    icon: "$info",
                    variant: "tonal",
                    title: _ctx.$t('details.genomics-alert-header'),
                    class: "mb-6",
                    "min-width": "350px"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("ul", null, [
                        _createElementVNode("li", null, _toDisplayString(_ctx.$t('details.genomics-alert-text-one')), 1),
                        _createElementVNode("li", null, _toDisplayString(_ctx.$t('details.genomics-alert-text-two')), 1),
                        _createElementVNode("li", null, _toDisplayString(_ctx.$t('details.genomics-alert-text-three')), 1),
                        _createElementVNode("li", null, _toDisplayString(_ctx.$t('details.genomics-alert-text-four')), 1),
                        _createElementVNode("li", null, _toDisplayString(_ctx.$t('details.genomics-alert-text-five')), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["title"]))
            ]),
            _: 1
          }),
          (_ctx.hasMarkerEffects)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                cols: "12",
                md: "6",
                class: "pl-md-6 pl-sm-0"
              }, {
                default: _withCtx(() => [
                  (_ctx.generalBreedingValueGroupList && _ctx.generalBreedingValueGroupList[0] && _ctx.generalBreedingValueGroupList[0].breedingValueList.length > 0)
                    ? (_openBlock(), _createBlock(_component_GeneralTableView, {
                        key: 0,
                        class: "mb-6",
                        "body-rows": _ctx.mapGeneralBreedingValues(
            _ctx.generalBreedingValueGroupList[0].breedingValueList,
            _ctx.generalBreedingValueGroupList[0].tagName
          )
          ,
                        "header-rows": _ctx.generalColumns
                      }, null, 8, ["body-rows", "header-rows"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.hasMarkerEffects)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "pr-md-6 pr-sm-0"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("details.production.header")), 1),
                  (_ctx.productionBreedingValueGroupList && _ctx.productionBreedingValueGroupList[0] && _ctx.productionBreedingValueGroupList[0].breedingValueList.length > 0)
                    ? (_openBlock(), _createBlock(_component_CustomTableView, {
                        key: 0,
                        class: "mb-6",
                        "body-rows": _ctx.mapCustomProductionBreedingValues(
            _ctx.productionBreedingValueGroupList[0].breedingValueList,
            _ctx.productionBreedingValueGroupList[0].tagName
          )
          ,
                        "header-rows": _ctx.customColumns
                      }, null, 8, ["body-rows", "header-rows"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("details.efficiency.header")), 1),
                  (_ctx.efficiencyBreedingValueGroupList && _ctx.efficiencyBreedingValueGroupList[0] && _ctx.efficiencyBreedingValueGroupList[0].breedingValueList.length > 0)
                    ? (_openBlock(), _createBlock(_component_CustomTableView, {
                        key: 1,
                        class: "mb-6",
                        "body-rows": _ctx.mapCustomBreedingValues(
            _ctx.efficiencyBreedingValueGroupList[0].breedingValueList,
            _ctx.efficiencyBreedingValueGroupList[0].tagName
          )
          ,
                        "header-rows": _ctx.customColumns
                      }, null, 8, ["body-rows", "header-rows"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("details.health.header")), 1),
                  (_ctx.healthBreedingValueGroupList && _ctx.healthBreedingValueGroupList[0] && _ctx.healthBreedingValueGroupList[0].breedingValueList.length > 0)
                    ? (_openBlock(), _createBlock(_component_GeneralTableView, {
                        key: 2,
                        class: "mb-6",
                        "body-rows": _ctx.mapGeneralBreedingValues(
            _ctx.healthBreedingValueGroupList[0].breedingValueList,
            _ctx.healthBreedingValueGroupList[0].tagName
          )
          ,
                        "header-rows": _ctx.generalColumns
                      }, null, 8, ["body-rows", "header-rows"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("details.functional.header")), 1),
                  (_ctx.functionalBreedingValueGroupList && _ctx.functionalBreedingValueGroupList[0] && _ctx.functionalBreedingValueGroupList[0].breedingValueList.length > 0)
                    ? (_openBlock(), _createBlock(_component_GeneralTableView, {
                        key: 3,
                        class: "mb-6",
                        "body-rows": _ctx.mapGeneralBreedingValues(
            _ctx.functionalBreedingValueGroupList[0].breedingValueList,
            _ctx.functionalBreedingValueGroupList[0].tagName
          )
          ,
                        "header-rows": _ctx.generalColumns
                      }, null, 8, ["body-rows", "header-rows"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "pl-md-6 pl-sm-0"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t("details.birth.header")), 1),
                  (_ctx.birthBreedingValueGroupList && _ctx.birthBreedingValueGroupList[0] && _ctx.birthBreedingValueGroupList[0].breedingValueList.length > 0)
                    ? (_openBlock(), _createBlock(_component_GeneralTableView, {
                        key: 0,
                        class: "mb-6",
                        "body-rows": _ctx.mapGeneralBreedingValues(
            _ctx.birthBreedingValueGroupList[0].breedingValueList,
            _ctx.birthBreedingValueGroupList[0].tagName
          )
          ,
                        "header-rows": _ctx.generalColumns
                      }, null, 8, ["body-rows", "header-rows"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("details.exteriorabove.header")), 1),
                  (_ctx.exteriorAboveBreedingValueGroupList && _ctx.exteriorAboveBreedingValueGroupList[0] && _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0)
                    ? (_openBlock(), _createBlock(_component_GeneralTableView, {
                        key: 1,
                        class: "mb-6",
                        "body-rows": _ctx.mapGeneralBreedingValues(
            _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList,
            _ctx.exteriorAboveBreedingValueGroupList[0].tagName
          )
          ,
                        "header-rows": _ctx.generalColumns
                      }, null, 8, ["body-rows", "header-rows"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("details.exteriorbelow.header")), 1),
                  (_ctx.exteriorBelowBreedingValueGroupList && _ctx.exteriorBelowBreedingValueGroupList[0] && _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0)
                    ? (_openBlock(), _createBlock(_component_GeneralTableView, {
                        key: 2,
                        class: "mb-6",
                        "body-rows": _ctx.mapGeneralBreedingValues(
            _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList,
            _ctx.exteriorBelowBreedingValueGroupList[0].tagName
          )
          ,
                        "header-rows": _ctx.generalColumns
                      }, null, 8, ["body-rows", "header-rows"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}