<script setup lang="ts">
// TODO: taken from crvSireMatch, candidate for @crv/vue-3-components
import { ref, watch } from 'vue'
import { refDebounced } from '@vueuse/core'


// * interface: (props and emits)
const props = withDefaults(
    defineProps<{
        modelValue?: string,
        headingText?: string,
        hint?: string,
        placeholder?: string,
        persistentHint?: boolean
        width?: string,
        hideDetails?: boolean,
        disabled?: boolean,
        readonly?: boolean,
        debounceInterval?: number,
        error: boolean,
        errorMessages?: string[],
    }>(),
    {
        modelValue: '',
        headingText: '',
        hint: '',
        placeholder: '',
        persistentHint: true,
        width: '100%',
        hideDetails: true,
        disabled: false,
        readonly: false,
        debounceInterval: 200,
        error: false,
    },
)

const emit = defineEmits(['update:model-value'])


// * refs
const searchValue = ref(props.modelValue)
const debouncedSearchValue = refDebounced(searchValue, props.debounceInterval)


// * watch
watch(
    debouncedSearchValue,
    () => {
        emit('update:model-value', debouncedSearchValue.value)
    }
)


// * methods
const handleClickInput = () => {
    init()
}

const handleClear = () => {
    searchValue.value = ''
}

const init = () => {
    // clears the input on click again, should this be configurable?
    // searchValue.value = ''
    // emit('update:model-value', searchValue.value)
}

defineExpose({init})

</script>


<template>
    <v-sheet
        :width="width"
    >
    <!-- apparently current app cannot handle loading the mdiIcon, vuetify needs an overhaul? -->
    <!-- :append-inner-icon="mdiMagnify" -->
        <v-text-field
            v-model.trim="searchValue"
            autofocus
            variant="underlined"
            color="primary"
            :label="headingText"
            :placeholder="placeholder"
            :hint="hint"
            :persistent-hint="persistentHint"
            :hide-details="hideDetails"
            :disabled="disabled"
            :readonly="readonly"
            :error="error"
            :error-messages="errorMessages"
            clearable
            @click="handleClickInput"
            @click:clear="handleClear"
        />
    </v-sheet>
</template>