import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pl-md-0 pl-3" }
const _hoisted_2 = { class: "pl-md-0 pl-3" }
const _hoisted_3 = { class: "pl-md-0 pl-3 mb-1" }
const _hoisted_4 = { class: "pl-md-0 pl-3 mb-1" }
const _hoisted_5 = { class: "pl-md-0 pl-3 mb-1" }
const _hoisted_6 = { class: "pl-md-0 pl-3 mb-1" }
const _hoisted_7 = { class: "pl-md-0 pl-3 mb-1" }
const _hoisted_8 = { class: "pl-md-0 pl-3 mb-1" }
const _hoisted_9 = { class: "pl-md-0 pl-3 mb-4" }
const _hoisted_10 = { class: "pl-md-0 pl-3 mb-4" }
const _hoisted_11 = { class: "pl-md-0 pl-3 mb-4" }
const _hoisted_12 = { class: "pl-md-0 pl-3 mb-4" }
const _hoisted_13 = { class: "pl-md-0 pl-3 mb-4" }
const _hoisted_14 = { class: "pl-md-0 pl-3 mb-1" }
const _hoisted_15 = { class: "pl-md-0 pl-3 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "pl-0 pr-0"
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("formulas.header")), 1),
      _createVNode(_component_v_btn, {
        variant: "text",
        color: "primary",
        class: "mb-6",
        "append-icon": _ctx.mdiOpenInNew,
        onClick: _ctx.openChapterLink
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("formulas.chapters")), 1)
        ]),
        _: 1
      }, 8, ["append-icon", "onClick"]),
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("formulas.source.header")), 1),
      _createVNode(_component_v_row, { class: "mb-6" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            class: "pr-md-6 pr-sm-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_table, {
                density: "compact",
                class: "fixed-width-first-child"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("tbody", null, [
                    _createElementVNode("tr", null, [
                      _cache[0] || (_cache[0] = _createElementVNode("td", null, "Nat", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.source.text.nat")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[1] || (_cache[1] = _createElementVNode("td", null, "Int", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.source.text.int")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[2] || (_cache[2] = _createElementVNode("td", null, "OFw", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.source.text.ofw")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[3] || (_cache[3] = _createElementVNode("td", null, "GNat", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.source.text.gnat")), 1)
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            class: "pl-md-6 pl-sm-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_table, {
                density: "compact",
                class: "fixed-width-first-child"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("tbody", null, [
                    _createElementVNode("tr", null, [
                      _cache[4] || (_cache[4] = _createElementVNode("td", null, "GFw", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.source.text.gfw")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[5] || (_cache[5] = _createElementVNode("td", null, "IGFw", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.source.text.igfw")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[6] || (_cache[6] = _createElementVNode("td", null, "Gint", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.source.text.gint")), 1)
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("details.summary.first.inheritedFactors")), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('formulas.inheritedLegend.description')), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('formulas.inheritedLegend.plus')), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('formulas.inheritedLegend.minus')), 1),
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('formulas.inheritedLegend.asterix')), 1),
      _createVNode(_component_v_row, { class: "mb-6" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            class: "pr-md-6 pr-sm-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_table, {
                density: "compact",
                class: "fixed-width-first-child"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("tbody", null, [
                    _createElementVNode("tr", null, [
                      _cache[7] || (_cache[7] = _createElementVNode("td", null, "BD", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.bd")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[8] || (_cache[8] = _createElementVNode("td", null, "BL", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.bl")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[9] || (_cache[9] = _createElementVNode("td", null, "BR", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.br")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[10] || (_cache[10] = _createElementVNode("td", null, "BY", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.by")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[11] || (_cache[11] = _createElementVNode("td", null, "CD", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.cd")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[12] || (_cache[12] = _createElementVNode("td", null, "CV", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.cv")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[13] || (_cache[13] = _createElementVNode("td", null, "D1", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.d1")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[14] || (_cache[14] = _createElementVNode("td", null, "D2", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.d2")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[15] || (_cache[15] = _createElementVNode("td", null, "DP", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.dp")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[16] || (_cache[16] = _createElementVNode("td", null, "GG", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.gg")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[17] || (_cache[17] = _createElementVNode("td", null, "GT", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.gt")), 1)
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            class: "pl-md-6 pl-sm-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_table, {
                density: "compact",
                class: "fixed-width-first-child"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("tbody", null, [
                    _createElementVNode("tr", null, [
                      _cache[18] || (_cache[18] = _createElementVNode("td", null, "LR", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.lr")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[19] || (_cache[19] = _createElementVNode("td", null, "MF", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.mf")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[20] || (_cache[20] = _createElementVNode("td", null, "MW", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.mw")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[21] || (_cache[21] = _createElementVNode("td", null, "OT", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.ot")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[22] || (_cache[22] = _createElementVNode("td", null, "PO", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.po")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[23] || (_cache[23] = _createElementVNode("td", null, "QT", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.qt")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[24] || (_cache[24] = _createElementVNode("td", null, "RF", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.rf")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[25] || (_cache[25] = _createElementVNode("td", null, "RT", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.rt")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[26] || (_cache[26] = _createElementVNode("td", null, "TV", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.tv")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[27] || (_cache[27] = _createElementVNode("td", null, "VR", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.vr")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[28] || (_cache[28] = _createElementVNode("td", null, "ZN", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t("formulas.inherited.zn")), 1)
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            class: "pr-md-6 pr-sm-0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.$t("details.summary.first.kinshipRate")), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('formulas.kinshipRate.text-one')), 1),
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('formulas.kinshipRate.text-two')), 1),
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('formulas.kinshipRate.text-three')), 1),
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('formulas.kinshipRate.text-four')), 1),
              _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('formulas.kinshipRate.text-five')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            class: "pl-md-6 pl-sm-0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_14, _toDisplayString(_ctx.$t("formulas.relative.header")), 1),
              _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("formulas.relative.text")), 1),
              _createVNode(_component_v_table, {
                density: "compact",
                class: "fixed-width-first-child"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerRows[0].cells, (item) => {
                        return (_openBlock(), _createElementBlock("th", {
                          key: item.id
                        }, _toDisplayString(item.value), 1))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyRows, (row) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: row.id
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cells, (cell) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: cell.id
                          }, _toDisplayString(cell.value), 1))
                        }), 128))
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}